<!--
 * ======================================
 * 说明： 任务管理
 * 作者： Silence
 * 文件： index.vue
 * 日期： 2023/5/14 13:40
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <div>
    <Main
      class="h-full"
      ref="main"
      :on-params="onParams"
      :columns="columns"
      :is-selection="false"
      :on-result="onResult"
      api="/evaluation/list"
      v-loading="loading"
    >
      <template #action>
        <el-button type="success" size="mini" @click="handleCreateTask"
          >新增</el-button
        >
      </template>
      <template #search>
        <Search
          v-model="searchForm"
          :options="searchOptions"
          @onSearch="onFind()"
        ></Search>
      </template>
      <template #table-item-scope="{ row }">
        <template v-if="row['scope'] && row['scope'].length">
          {{
            row["scope"]
              .map((key) =>
                key === 7 ? "七年级" : key === 8 ? "八年级" : "九年级"
              )
              .join("，")
          }}
        </template>
        <template v-else>-</template>
      </template>
      <template #table-item-status="{ row }">
        <template v-if="row['status'] === 'release'">
          <el-tag type="success">已发布</el-tag>
        </template>
        <template v-else-if="row['status'] === 'down'">
          <el-tag type="danger">已下架</el-tag>
        </template>
        <template v-else>
          <el-tag>待发布</el-tag>
        </template>
      </template>
      <template #table-item-start_time="{ row }">
        <template v-if="row['start_date'] || row['end_date']">
          {{ row["start_date"] }} 至 {{ row["end_date"] }}
        </template>
        <template v-else>-</template>
      </template>
      <template #table-item-action="{ row }">
        <el-button
          type="text"
          class="text-red-600"
          @click="handleCheckTask(row, 'release')"
          >查看</el-button
        >
        <el-button
          v-if="row['status'] === 'draft'"
          type="text"
          class="text-red-600"
          @click="handlePublishTask(row, 'release')"
          >发布</el-button
        >
        <el-button
          v-if="row['status'] === 'draft'"
          type="text"
          class="text-red-600"
          @click="handleUpdateTask(row)"
          >修改</el-button
        >
        <el-button
          v-if="row['status'] === 'release'"
          type="text"
          class="text-red-600"
          @click="handlePublishTask(row, 'down')"
          >下架</el-button
        >
        <el-button type="text" class="text-red-600" @click="handleCopy(row)"
          >复制</el-button
        >
        <el-button
          v-if="row['status'] !== 'release'"
          type="text"
          class="text-red-600"
          @click="handleDeleteTask(row)"
          >删除</el-button
        >
      </template>
    </Main>
    <Edit ref="edit" @refresh="onRefresh"></Edit>
  </div>
</template>
<script>
import Main from "../components/main/index.vue";
import Search from "../components/search/index.vue";
import Edit from "./components/edit.vue";
import { columns, searchOptions } from "./config.js";
export default {
  name: "",
  components: { Main, Search, Edit },
  props: {},
  data() {
    return {
      columns,
      searchOptions,
      searchForm: {},
      loading: false,
    };
  },
  watch: {},
  computed: {},
  created() {},
  async mounted() {
    this.$nextTick(async () => {
      await this.onFind();
      setTimeout(async () => {
        await this.$refs["edit"].handleGetDimensions(); // 获取维度
      }, 500);
    });
  },
  methods: {
    /**
     *  @actions:  查询参数
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/14 15:03
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    onParams() {
      return this.searchForm;
    },

    /**
     *  @actions:  查询列表回调
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/19 21:56
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    async onResult() {
      // await this.$refs["edit"].handleGetDimensions(); // 获取维度
    },

    /**
     *  @actions:  查询列表数据
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/14 15:02
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    async onFind() {
      this.$nextTick(async () => {
        await this.$refs["main"].onFind();
      });
    },

    /**
     *  @actions:  刷新列表
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/15 11:58
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    onRefresh() {
      this.$refs["main"].onQuery();
    },

    /**
     *  @actions:  查询任务内容
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/20 0:07
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleCheckTask(row) {
      this.$refs["edit"].open({ mode: 3, editForm: row });
    },

    /**
     *  @actions:  新增任务
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/18 23:52
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleCreateTask() {
      this.$refs["edit"].open({ mode: 1 });
    },

    /**
     *  @actions:  正式发布
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/14 15:00
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handlePublishTask(row, status) {
      let massage = "确定要发布该任务评价吗？";
      let url = "/evaluation/publish";
      if (status === "down") {
        massage = "确定要下架改任务评价吗，下架后将无法继续评价？";
        url = "/evaluation/down";
      }
      this.$confirm(massage, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.loading = true;
        this.$cloud
          .post(url, { id: row.id })
          .then(() => {
            this.loading = false;
            if (status === "down") {
              this.$message.success("下架成功！");
            } else {
              this.$message.success("发布成功！");
            }
            this.onRefresh();
          })
          .catch((err) => {
            this.$message.error(err);
            this.loading = false;
          });
      });
    },
    /**
     * ======================================
     * 说明： 复制
     * 作者： piaoyizhe
     * 文件： index.vue
     * 日期： 2023/11/10 15:52
     * ======================================
     */
    handleCopy(row) {
      console.log("复制", row);
      this.$confirm("确定要复制当前任务吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$cloud
          .post("/evaluation/copy", { id: row.id })
          .then(() => {
            this.$message.success("复制成功！");
            this.onRefresh();
          })
          .catch((err) => {
            this.$message.error(err);
          });
      });
    },

    /**
     *  @actions:  更新任务
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/19 9:05
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleUpdateTask(row) {
      this.$refs["edit"].open({ mode: 2, editForm: row });
    },

    /**
     *  @actions:  删除任务
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/14 14:59
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleDeleteTask(row) {
      if (row.status === "release") {
        this.$message.error("发布中的任务不可删除！");
        return false;
      }
      this.$confirm("确定删除该评价任务吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.loading = true;
        this.$cloud
          .get("/evaluation/delete", { id: row["id"] })
          .then(() => {
            this.loading = false;
            this.$message.success("删除成功！");
            this.onRefresh();
          })
          .catch((err) => {
            this.$message.error(err);
            this.loading = false;
          });
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
