<!--
 * ======================================
 * 说明： 评价任务创建与编辑
 * 作者： Silence
 * 文件： edit.vue
 * 日期： 2023/5/18 23:52
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <Dialog
    :title="`${title}任务`"
    :is-confirm="isConfirm"
    confirmText="保 存"
    width="80%"
    height="96%"
    v-model="visible"
    @handleConfirm="handleSubmit"
  >
    <div
      class="h-full border border-solid p-5 pb-0"
      v-loading="loading"
      style="overflow: hidden; overflow-y: auto"
    >
      <el-form
        ref="form"
        :model="formData"
        label-width="80px"
        :disabled="disabled"
      >
        <el-form-item label="任务名称">
          <el-input v-model="formData.name"></el-input>
        </el-form-item>

        <el-form-item label="任务时间">
          <el-col :span="6">
            <el-date-picker
              type="date"
              placeholder="开始日期"
              style="width: 100%"
              v-model="formData.start_date"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
            ></el-date-picker>
          </el-col>
          <el-col class="line text-center" :span="1">-</el-col>
          <el-col :span="6">
            <el-date-picker
              type="date"
              placeholder="结束日期"
              style="width: 100%"
              v-model="formData.end_date"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
            ></el-date-picker>
          </el-col>
        </el-form-item>
        <el-form-item label="任务范围">
          <el-checkbox-group v-model="formData.scope">
            <el-checkbox :label="7">七年级</el-checkbox>
            <el-checkbox :label="8">八年级</el-checkbox>
            <el-checkbox :label="9">九年级</el-checkbox>
          </el-checkbox-group>
        </el-form-item>

        <el-form-item label="任务描述">
          <el-input
            type="textarea"
            :rows="3"
            v-model="formData.desc"
          ></el-input>
        </el-form-item>

        <el-form-item label="限制说明">
          <el-input
            type="textarea"
            :rows="3"
            v-model="formData.restrictionDesc"
          ></el-input>
        </el-form-item>
        <el-form-item label="评价规则">
          <div class="p-0">
            <div class="pb-2">
              <el-button type="primary" @click="handleAddDimension"
                >添加维度</el-button
              >
              <el-button type="danger" @click="handleDelDimension"
                >删除维度</el-button
              >
            </div>
            <el-row><el-col><span class="text-red-600">评分编号规则：P+数字 （示例：P1）</span></el-col></el-row>
            <el-row :gutter="10">
              <el-col :span="1"> #</el-col>
              <el-col :span="3"> 维度</el-col>
              <el-col :span="3"> 指标</el-col>
              <el-col :span="13">
                <el-row :gutter="10">
                  <el-col :span="4">打分编号</el-col>
                  <el-col :span="8">打分项</el-col>
                  <el-col :span="6">分值</el-col>
                  <el-col :span="6">评分人员</el-col>
                </el-row>
              </el-col>
              <el-col :span="4"> 合计公式</el-col>
            </el-row>
            <el-checkbox-group v-model="selectDimensions">
              <el-row
                v-for="(item, index) in formData.dimensions"
                :key="index"
                :gutter="10"
                class="m-2 p-2 border border-red-500"
              >
                <el-col :span="1"
                  ><el-checkbox :label="Number(index)">{{
                    ""
                  }}</el-checkbox></el-col
                >
                <el-col :span="3">
                  <el-select
                    v-model="formData.dimensions[index].id"
                    placeholder="请选择维度"
                  >
                    <el-option
                      v-for="item in dimensions"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="3">
                  <div v-for="(t, i) in item.indicators" :key="i" class="mb-2">
                    <el-select
                      v-model="formData.dimensions[index].indicators[i]"
                      placeholder="请选择指标"
                    >
                      <el-option
                        v-for="item in indicators"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </div>
                  <el-button
                    icon="el-icon-circle-plus-outline"
                    @click="item.indicators.push([1])"
                    circle
                  ></el-button>
                  <el-button
                    type="danger"
                    icon="el-icon-delete"
                    circle
                    @click="
                      item.indicators.splice(item.indicators.length - 1, 1)
                    "
                  ></el-button>
                </el-col>
                <el-col :span="13">
                  <el-row
                    v-for="(m, i) in item.marks"
                    :key="i"
                    :gutter="10"
                    class="mb-2"
                  >
                    <el-col :span="4">
                      <el-input
                        v-model="formData.dimensions[index].marks[i].no"
                      ></el-input>
                    </el-col>
                    <el-col :span="8">
                      <el-select
                        v-model="formData.dimensions[index].marks[i].targeted"
                        multiple
                        collapse-tags
                        placeholder="请选择指标"
                      >
                        <el-option
                          v-for="item in indicators"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                        >
                        </el-option>
                      </el-select>
                    </el-col>
                    <el-col :span="6">
                      <el-input
                        v-model="formData.dimensions[index].marks[i].value"
                      ></el-input>
                    </el-col>
                    <el-col :span="6">
                      <el-select
                        v-model="
                          formData.dimensions[index].marks[i].from_subject_id
                        "
                        placeholder="评分人员"
                      >
                        <el-option
                          v-for="item in subjects"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                        >
                        </el-option>
                      </el-select>
                    </el-col>
                  </el-row>
                  <el-button
                    @click="
                      item.marks.push({
                        no: '',
                        targeted: [],
                        value: 0,
                        from_subject_id: 1,
                      })
                    "
                    >添加打分项
                  </el-button>
                  <el-button
                    @click="item.marks.splice(item.marks.length - 1, 1)"
                    >删除打分项</el-button
                  >
                </el-col>
                <el-col :span="4">
                  <el-input
                    v-model="formData.dimensions[index].formula"
                  ></el-input>
                </el-col>
              </el-row>
            </el-checkbox-group>
          </div>
        </el-form-item>
        <!--      <el-form-item>-->
        <!--        <el-button type="primary" @click="handleSubmit">立即创建</el-button>-->
        <!--        <el-button>取消</el-button>-->
        <!--      </el-form-item>-->
      </el-form>
    </div>
  </Dialog>
</template>
<script>
import Dialog from "@/pages/components/dialog";
export default {
  name: "",
  components: { Dialog },
  props: {},
  data() {
    return {
      title: "新增",
      visible: false,
      mode: 1,
      disabled: false,
      isConfirm: true,
      selectDimensions: [], // 选中的维度
      defaultFormData: {
        name: "",
        start_time: "",
        end_time: "",
        scope: [],
        desc: "",
        restrictionDesc: "",
        dimensions: [
          {
            id: "",
            indicators: [""],
            formula: "",
            marks: [
              {
                targeted: [],
                no: "",
                value: "",
                from_subject_id: "",
              },
            ],
          },
        ],
      },
      formData: {},
      dimensions: [],
      indicators: [],
      subjects: [],
      loading: false,
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    /**
     *  @actions:  打开弹窗
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/18 23:56
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    open(data) {
      this.formData = JSON.parse(JSON.stringify(this.defaultFormData));
      this.mode = data.mode;
      this.title = "新增";
      this.disabled = false;
      this.isConfirm = true;
      if (this.mode === 2) {
        this.title = "更新";
        this.formData = data["editForm"];
        this.$set(this.formData,'restrictionDesc',this.formData["restriction_desc"]) // 接返回的字段不是驼峰的
      } else if (this.mode === 3) {
        this.title = "查看";
        this.disabled = true;
        this.isConfirm = false;
        this.formData = data["editForm"];
        this.$set(this.formData,'restrictionDesc',this.formData["restriction_desc"]) // 接返回的字段不是驼峰的
      }

      this.visible = true;
    },

    /**
     *  @actions:  获取维度
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/15 10:44
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    async handleGetDimensions() {
      await this.$cloud
        .get("/dimension/list", { pageSize: 999, pageIndex: 1 })
        .then(async (res) => {
          console.log("res.list", res.list);
          this.dimensions = res.list;
          await this.handleGetIndicators(); // 获取指标
        });
    },
    /**
     *  @actions:  获取指标
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/15 10:44
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    async handleGetIndicators() {
      await this.$cloud
        .get("/indicator/list", { pageSize: 999, pageIndex: 1 })
        .then(async (res) => {
          this.indicators = res.list;
          await this.handleGetSubject();
        });
    },
    // 获取班级待选项
    async handleGetSubject() {
      this.loading = true;
      await this.$cloud
        .get("/subject/list", {
          pagelndex: 1,
          pageSize: 999,
        })
        .then((res) => {
          this.loading = false;
          if (res.list && typeof res.list == "object" && res.list.length) {
            this.subjects = res.list;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    /**
     *  @actions:  添加维度
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/19 0:39
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleAddDimension() {
      this.formData.dimensions.push(
        JSON.parse(JSON.stringify(this.defaultFormData.dimensions[0]))
      );
    },

    /**
     *  @actions:  删除维度
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/19 0:41
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleDelDimension() {
      if (!this.selectDimensions.length) {
        this.$message.error("请选择要删除评价维度！");
      } else {
        this.formData.dimensions.map((item, index) => {
          return this.$set(item, "index", index);
        });

        this.$confirm("确定要删除选中的评价维度吗，该操作不可逆?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          let dimensions = this.formData.dimensions.filter(
            (item) => !this.selectDimensions.includes(item.index)
          );
          this.$set(this.formData, "dimensions", dimensions);
          this.selectDimensions = [];
        });
      }
    },

    /**
     * 用于提交数据结构
     */
    async handleSubmit() {
      this.formData["startDate"] = this.formData.start_date;
      this.formData["endDate"] = this.formData.end_date;
      let message = "确定新增任务吗？",
        url = "/evaluation/create";
      if (this.mode === 2) {
        message = "确定更新当前任务吗？";
        url = "/evaluation/edit";
      }

      this.$confirm(message, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        this.loading = true;
        await this.$cloud
          .post(url, this.formData)
          .then((res) => {
            this.loading = false;
            if (this.mode === 2) {
              this.$message.success("更新成功");
            } else {
              this.$message.success("新增成功");
            }
            this.$emit("refresh");
            this.visible = false;
            console.log("提交数据结构", res);
          })
          .catch((err) => {
            this.$message.error(err);
            this.loading = false;
          });
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
