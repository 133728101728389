/**
 * ======================================
 * 说明： 任务管理配置文件
 * 作者： Silence
 * 文件： config.js
 * 日期： 2023/5/14 14:22
 * ======================================
 */
export const columns = [
  {
    field: "name",
    label: "任务名称",
    align: "left",
    width: "auto,",
  },
  {
    field: "scope",
    label: "评价对象",
    align: "left",
    width: "auto,",
  },
  {
    field: "start_time",
    label: "评分时间段",
    align: "left",
    width: "auto,",
  },
  {
    field: "publish_date",
    label: "发布时间",
    align: "left",
    width: "auto",
  },
  {
    field: "down_date",
    label: "下架时间",
    align: "left",
    width: "auto",
  },
  {
    field: "status",
    label: "发布状态",
    align: "left",
    width: "auto,",
  },
  {
    field: "action",
    label: "操作",
    align: "center",
    width: "160",
  },
];

export const searchOptions = [
  {
    type: "input",
    field: "name",
    label: "任务名称",
    placeholder: "按任务名称",
  },
];

export const indexColumns = [
  {
    field: "dimensionId",
    label: "评价维度",
    align: "left",
    width: "160",
  },
  {
    field: "indicatorId",
    label: "评价指标",
    align: "left",
    width: "160",
  },
  {
    field: "score",
    label: "评价分值",
    align: "center",
    width: "100,",
  },
  {
    field: "subjectList",
    label: "评价老师",
    align: "left",
    width: "auto,",
  },
];
